
import React, { useState, useEffect } from 'react';
import { Input, Button, Select, Form, Table, Skeleton, Space, message, Modal, Popconfirm, Row, Col } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { fetchResponseGroupInitialData, fetchResponseDataByGroupId, deleteGroupByGroupId, responseDeleteToGroup, responseAddToGroup, addNewKeyword, updateKeyword, responseUpdateToGroup } from '../../../services/admin/adminService';
import '../assets/styles/common.css';

const PossibleResponseGroup = () => {

  const MAPPING_CONST = {
    GETLONGRESPONSE: 'GETLONGRESPONSE',
    GETKEYWORD: 'GETKEYWORD',
    DELETEGROUP: 'DELETE',
    LONGRESPONSE: 'LONGRESPONSE',
    KEYWORDRESPONSE: 'KEYWORDRESPONSE'
  }

  const [responses, setResponses] = useState([]);
  const [filterResponses, setFilterResponses] = useState([]);
  const [responseSearchText, setResponseSearchText] = useState('');
  const [groupsData, setGroupsData] = useState(null);
  const [filterGroupsData, setFilterGroupsData] = useState(null);
  const [keywordSearchText, setKeywordSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseText, setResponsetext] = useState('');
  const [responseEditKey, setResponseEditKey] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [isResponseEditing, setIsResponseEditing] = useState(false);
  const [responseType, setResponseType] = useState('');

  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [keywordText, setKeywordtext] = useState('');
  const [keywordEditId, setKeywordEditId] = useState('');
  const [isKeywordEditClick, setIsKeywordEditClick] = useState(false);

  const [form] = Form.useForm();
  const columns = [
    {
      title: 'Group Id',
      dataIndex: 'groupId',
      key: 'groupId',
      hidden: true,
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: (a, b) => {
        const nameA = a.groupName || ''; // default to an empty string if undefined/null
        const nameB = b.groupName || ''; // default to an empty string if undefined/null
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Keyword Response', key: 'KeywordView',
      render: (text, record) => (
        <Space>
          <Button onClick={() => handleKeywordView(record)}><EyeOutlined /></Button>
        </Space>
      ),
    },
    {
      title: 'Long Response', key: 'responseView',
      render: (text, record) => (
        <Space>
          <Button onClick={() => handleResponseView(record)}><EyeOutlined /></Button>
        </Space>
      ),
    },
    {
      title: 'Actions', key: 'Actions',
      hidden: true,
      render: (text, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => handleGroupEdit(record)}></Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleGroupDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const responseTableColumns = [
    {
      title: 'Response',
      dataIndex: 'responseId',
      key: 'responseId',
      hidden: true,
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleResponseDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  //#region UseEffect
  useEffect(() => {
    async function fetchPopulateData() {
      setLoading(true);
      try {
        const response = await fetchResponseGroupInitialData();
        if (response !== undefined) {
          setGroupsData(response.groups.responseGroup);
          setFilterGroupsData(response.groups.responseGroup);
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchPopulateData();
  }, []);
  // Moved fetchPopulateData outside of useEffect
  const fetchPopulateData = async () => {
    setLoading(true);
    try {
      const response = await fetchResponseGroupInitialData();
      if (response !== undefined) {
        setGroupsData(response.groups.responseGroup);
        setFilterGroupsData(response.groups.responseGroup);
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPopulateData();  // Fetch initial data on component load
  }, []);
  //#endregion

  //#region Group Grid
  const handleKeywordView = async (record) => {
    setResponseType(MAPPING_CONST.KEYWORDRESPONSE);
    setResponsetext('');
    setIsResponseEditing(false)
    form.setFieldsValue({ response: '' });
    setSelectedGroupId('');
    setSelectedGroupName('');
    const groupId = record.groupId;
    try {
      setSelectedGroupId(groupId);
      setSelectedGroupName(record.groupName);
      const result = await fetchResponseDataByGroupId(groupId, MAPPING_CONST.GETKEYWORD);
      if (result !== undefined) {
        setResponses(result);
        setFilterResponses(result);
        setShowResponseModal(true);
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleResponseView = async (record) => {
    setResponseType(MAPPING_CONST.LONGRESPONSE);
    setResponsetext('');
    setIsResponseEditing(false)
    form.setFieldsValue({ response: '' });
    setSelectedGroupId('');
    setSelectedGroupName('');
    const groupId = record.groupId;
    try {
      setSelectedGroupId(groupId);
      setSelectedGroupName(record.groupName);
      const result = await fetchResponseDataByGroupId(groupId, MAPPING_CONST.GETLONGRESPONSE);
      if (result !== undefined) {
        setResponses(result);
        setFilterResponses(result);
        setShowResponseModal(true);
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setLoading(false);
    }
  }

  const addKeyword = async () => {
    try {
      await form.validateFields(['keywordText']).then(values => {
        if (!isKeywordEditClick) {
          const result = addNewKeyword(keywordText);
          if (result !== undefined) {
            message.success("Successfully added.");
            fetchPopulateData();
          }

          fetchPopulateData();
        }
        else {
          const result = updateKeyword(keywordEditId, keywordText);
          if (result !== undefined) {
            message.success("Successfully updated.");
            fetchPopulateData();
          }
          fetchPopulateData();
        }
        setKeywordtext('');
        setKeywordEditId('');
        form.resetFields();

      }).catch(errorInfo => {
        console.log('Validation failed:', errorInfo);
      });

    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setLoading(false);
      setShowKeywordModal(false);
      setIsKeywordEditClick(false);
    }
  }

  const handleGroupEdit = async (record) => {
    setIsKeywordEditClick(true);
    setShowKeywordModal(true);
    setKeywordtext(record.groupName);
    setKeywordEditId(record.groupId);
    form.setFieldsValue({ keywordText: record.groupName })
  }

  const handleGroupDelete = async (record) => {
    const groupId = record.groupId;
    try {
      const result = await deleteGroupByGroupId(groupId);
      if (result !== undefined) {
        message.success("Successfully Deleted.");
        fetchPopulateData();
      }
      fetchPopulateData();
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleKeywordSearch = (value) => {
    setKeywordSearchText(value);
    if (value === '') {
      setFilterGroupsData(groupsData);
    } else {
      const filtered = groupsData.filter(item => {
        const groupName = item['groupName'];
        return groupName && groupName.toString().toLowerCase().includes(value.toLowerCase());
      });
      setFilterGroupsData(filtered);
    }
  };

  const handleKeywordSearchClear = () => {
    setKeywordSearchText('');
    setFilterGroupsData(groupsData);
  }

  //#endregion

  //#region response modal
  const handleEdit = (record) => {
    setIsResponseEditing(true);
    setResponsetext(record.response);
    setResponseEditKey(record.responseId);
    form.setFieldsValue({ response: record.response });
  };

  const handleResponseDelete = async (record) => {
    try {
      const result = await responseDeleteToGroup(record.responseId, responseType);
      if (result !== undefined) {
        message.success("Successfully Deleted.");
        fetchPopulateData();
      }
      fetchPopulateData();
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
    }
  };

  const addResponseToGroup = async () => {
    await form.validateFields(['response']).then(values => {
      if (!isResponseEditing) {
        //Insert a response      
        const result = responseAddToGroup(selectedGroupId, selectedGroupName, responseText, responseType);
        if (result !== undefined) {
          message.success("Successfully Added.");
          fetchPopulateData();
          setResponsetext('');
          form.resetFields();
        } fetchPopulateData();
      }
      else {
        //Update response
        if (responseEditKey != null && responseEditKey != "") {
          const result = responseUpdateToGroup(selectedGroupId, responseEditKey, responseText, responseType);
          if (result !== undefined) {
            message.success("Successfully Updated.");

            setResponsetext('');
            setResponseEditKey('');
            form.resetFields();
            fetchPopulateData();
          }
          fetchPopulateData();

        }

        else {
          message.error("Error on Edit key.");
        }
      }
    }).catch(errorInfo => {
      console.log('Validation failed:', errorInfo);
    });
  }

  const handleResponseSearch = (value) => {
    setResponseSearchText(value);
    if (value === '') {
      setFilterResponses(responses);
    } else {
      const filtered = responses.filter(item =>
        item['response'].toString().toLowerCase().includes(value.toLowerCase())
      );
      setFilterResponses(filtered);
    }
  };


  const handleResponseSearchClear = () => {
    setResponseSearchText('');
    setFilterResponses(responses);
  }

  //#endregion


  return (
    <div className='admin-possible-response'>
      <h2>Possible Response</h2>
      <div>
        <div>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={18}>
              <Button type="primary" onClick={() => { setKeywordtext(''); form.resetFields(); setIsKeywordEditClick(false); setShowKeywordModal(true); }}>Add Keyword</Button>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={22}>
                  <Input
                    className='cus-antd-white'
                    placeholder="Search in table"
                    value={keywordSearchText}
                    onChange={e => handleKeywordSearch(e.target.value)}
                    style={{ maxWidth: 300 }}
                  />
                </Col>
                <Col span={2}>
                  <Button type='text' style={{ padding: '0 0 0 10px' }} shape='default' onClick={handleKeywordSearchClear}>
                    <CloseCircleOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Modal
          width={"400px"}
          title="Add Keyword"
          open={showKeywordModal}
          onCancel={() => { setShowKeywordModal(false); setIsKeywordEditClick(false); }}
          footer={[
            <Button key="AddKeyword" type="primary" onClick={addKeyword}>
              {isKeywordEditClick ? 'Update' : 'Add'}
            </Button>
          ]}
        >
          <Form form={form} layout="vertical" initialValues={keywordText}>
            <Form.Item
              name="keywordText"
              label="Keyword"
              rules={[{ required: true, message: 'Field should not be empty' }]}
            >
              <Input placeholder='Enter here' value={keywordText} onChange={e => setKeywordtext(e.target.value)} />
            </Form.Item>
          </Form>
        </Modal>

        <div>
          <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
            <Table size='small' bordered={true} columns={columns} className='cus-antd-white' dataSource={filterGroupsData} />
            <div>
              <Modal
                width={"50%"}
                title="Add Response"
                open={showResponseModal}
                onCancel={() => { setShowResponseModal(false); setSelectedGroupId(''); setSelectedGroupName(''); setResponseType(''); setResponseEditKey(''); }}
                footer={[
                  <Button key="back" onClick={() => { setShowResponseModal(false); setSelectedGroupId(''); setSelectedGroupName(''); setResponseType(''); setResponseEditKey(''); }}>Cancel</Button>
                ]}
              >
                <Row>
                  <Col span={14}>
                    <Form form={form} layout="vertical" initialValues={responseText}>
                      <Row>
                        <Col span={20}>
                          <Form.Item
                            style={{ width: '90%' }}
                            name="response"
                            label="Response"
                            rules={[{ required: true, message: 'Field should not be empty' }]}
                          >
                            <Input placeholder='Enter here' value={responseText} onChange={e => setResponsetext(e.target.value)} />
                          </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '1.8rem' }}>
                          <Button style={{ marginBottom: '10px' }} type="primary" onClick={addResponseToGroup}>
                            {isResponseEditing ? 'Update' : 'Add'}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col span={10}>
                    <Row>
                      <Col span={22}>
                        <label>Search</label>
                        <Input
                          placeholder="Search in table"
                          value={responseSearchText}
                          onChange={e => handleResponseSearch(e.target.value)}
                          style={{ maxWidth: 300, marginTop: '10px' }}
                        />
                      </Col>
                      <Col span={2}>
                        <Button type='text' style={{ padding: '0 0 0 10px', marginTop: '1.8rem' }} shape='default' onClick={handleResponseSearchClear}>
                          <CloseCircleOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div>
                  <Table size='small' bordered={true} dataSource={filterResponses} className='cus-antd-white' columns={responseTableColumns} />
                </div>
              </Modal>
            </div>
          </Skeleton>
        </div>
      </div >
    </div >

  );
};

export default PossibleResponseGroup;
