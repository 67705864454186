
import React from 'react';
import { Layout } from 'antd';
import loginBg from '../assets/styles/loginbg.jpg'; 

const { Header, Content, Footer } = Layout;

const LoginMasterLayout = ({ children }) => {
  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundImage: `url(${loginBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Header style={{ color: '#fff', textAlign: 'center', fontSize: '24px' }}>
        First Outcomes - Workflow Admin Panel
      </Header>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 64px - 70px)'
        }}
      >
        {children}
      </Content>
      <Footer style={{ textAlign: 'center', color: '#000' }}>
        ©2024 Anjusoft (India) Pvt. Ltd.,
      </Footer>
    </Layout>
  );
};

export default LoginMasterLayout;
