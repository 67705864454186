import React, { useState, useRef, useEffect } from 'react';
import { Form, Select, Input, Button, Row, Col, Checkbox, Table, Tooltip, Dropdown, Menu, Card, Drawer } from 'antd';
import { SettingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchBranchInitialData } from '../../../services/componentService';
import { fetchResponseDataByGroupId } from '../../../services/admin/adminService';
import { passOutputToParent, isJsonString } from '../../../assets/js/common.js';

const { Option } = Select;
const { TextArea } = Input;

const MAPPING_CONST = {
  GETLONGRESPONSE: 'GETLONGRESPONSE',
  GETKEYWORD: 'GETKEYWORD',
};

const CONST_MAPPING = {
  NEXT_WORKFLOW: "NEXTWORKFLOW",
  SAME_COMPONENT: "SAMECOMPONENT",
  PROCESS_TYPE_API: 'API CONFIG',
  PROCESS_TYPE_SP: 'SP CALL',
  PROCESS_TYPE_MESSAGE: 'MESSAGE BUILD',
  OPERATOR_WORKFLOW_ID: 2,
  OPERATOR_LINE_TEXT: 'operator',
  MULTISELECT_MAX_DISPLAY: 2,
}

const Inbound = () => {
  const [form] = Form.useForm();
  const [selectedFieldType, setSelectedFieldType] = useState(null);
  const textAreaRef = useRef(null);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedParametersText, setSelectedParametersText] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [inboundMessageSelectedOptions, setInboundMessageSelectedOptions] = useState([]);
  const [keywordResponse, setKeywordResponse] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Manage dropdown visibility
  const [analyzeType, setAnalyzeType] = useState('');
  const [nodeKey, setNodeKey] = useState("");
  const [messageType, setMessageType] = useState('Voice');

  useEffect(() => {
    const role = sessionStorage.getItem('roleId');
    if (role) {
    } else {
    }

    const loadDropdownData = async () => {
      try {
        const response = await fetchBranchInitialData();
        if (response.data) {
          if (response.data.possibleResponseGroup) {
            setDropdownOptions(response.data.possibleResponseGroup);
          }
          if (response.data.messageIndexData) {
            setSelectedParametersText(response.data.messageIndexData);
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    loadDropdownData();
  }, []);

  useEffect(() => {
    const fetchComponentData = () => {
      window.addEventListener('message', compDataLoad);
      return () => {
        window.removeEventListener('message', compDataLoad);
      };
    };
    fetchComponentData();
  }, []);

  const compDataLoad = (event) => {
    if (event.source === window.parent) {
      if (event.data) {
        setNodeKey(event.data.key);
        const incomingData = event.data;
        if (isJsonString(incomingData.data)) {
          const loadData = JSON.parse(incomingData.data);
          console.log("compDataLoad:", loadData);
          if (loadData.loadJson) {
            const processData = loadData.loadJson;
            if (processData.configData) {
              form.setFieldsValue(processData.configData);
            }
          }
        }
      }
    }
  };
  const ChangeMessageType = (value) => {
    setMessageType(value);
  }

  const onFinish = (values) => {
    const selectedResponses = values.inboundMessage || [];

    const lineItems = selectedResponses.map((response, index) => ({
      key: index + 1,
      lineType: 'nextcomponent',
      lineNextFlow: 0,
      lineNextFlowText: '',
      lineText: response,
    }));

    const defaultLines = {
      key: -1,
      lineType: "nextworkflow",
      lineNextFlow: CONST_MAPPING.OPERATOR_WORKFLOW_ID,
      lineText: `${CONST_MAPPING.OPERATOR_LINE_TEXT}|${CONST_MAPPING.OPERATOR_WORKFLOW_ID}`,
    };

    const linesData = { lines: lineItems.concat(defaultLines) };
    const configData = {
      ...values,
      messageType: messageType,
    };

    const fullData = {
      key: nodeKey,
      configData: configData,
      configLines: linesData,
    };
    console.log("Full Data:", fullData);
    passOutputToParent(JSON.stringify(fullData));
  };


  const handleMenuClick = (e) => {
    const selectedType = `{${e.key}}`;
    setSelectedFieldType(selectedType);

    if (textAreaRef.current) {
      const textArea = textAreaRef.current.resizableTextArea.textArea;
      const cursorPosition = textArea.selectionStart;
      const text = textArea.value;

      const newText = text.slice(0, cursorPosition) + selectedType.replace(/@/g, '') + text.slice(cursorPosition);
      setTextAreaValue(newText);
      form.setFieldsValue({ outboundMessage: newText });
    }
  };

  const handleTextAreaChange = (e) => {
    const value = e.target.value.replace(/@/g, '');
    setTextAreaValue(value);
    form.setFieldsValue({ outboundMessage: value });
  };

  const filteredParametersText = selectedParametersText.filter((param) =>
    param.messageIndexData.toLowerCase().includes(searchText.toLowerCase())
  );

  const menu = (
    <Menu onClick={handleMenuClick} style={{ height: '200px', overflow: 'auto' }}>
      <Menu.Item key="search" disabled>
        <Input
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Menu.Item>
      {filteredParametersText.map((param) => (
        <Menu.Item key={param.messageIndexData}>{param.messageIndexData.replace(/@/g, '')}</Menu.Item>
      ))}
    </Menu>
  );

  const handleCancel = () => {
    form.resetFields();
    setTextAreaValue('');
    setAnalyzeType('');
  };

  const showDrawer = () => {
    setInboundMessageSelectedOptions(form.getFieldValue('inboundMessage') || []);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setKeywordResponse([]);
  };

  const handleDrawerSelectChange = async (value) => {
    try {
      const selectedOption = dropdownOptions.find(option => option.groupName === value);
      if (selectedOption) {
        const result = await fetchResponseDataByGroupId(selectedOption.groupId, MAPPING_CONST.GETKEYWORD);
        setKeywordResponse(result);
      }
    } catch (error) {
      console.error('Failed to fetch keyword response:', error);
    }
  };

  const columns = [
    {
      title: 'Keyword Response',
      dataIndex: 'response',
      key: 'response',
    },
  ];

  const handleCheckboxChange = (e) => {
    setIsDropdownVisible(e.target.checked);
    if (!e.target.checked) {
      setAnalyzeType('');
    }
  };

  const handleAnalyzeTypeChange = (value) => {
    setAnalyzeType(value);
  };

  return (
    <div className="component-container">
      <Card
        className="cus-card"
        title={

          <Row align="middle">
            <Col>
              <SettingOutlined className="cus-card-title-icon" />
            </Col>
            <Col>
              <span>Settings</span>
            </Col>
          </Row>
        }
      ><Row span={[24]}>
          <Col span={[3]}>
            <div className='cus_lable' style={{ marginBottom: "10px" }}>
              <label>Message Type</label>
              <span style={{ paddingLeft: "10px" }}>
                <Tooltip title="Change message type either 'Text' or 'Voice'. Default type is 'Voice'">
                  <ExclamationCircleOutlined />
                </Tooltip>
              </span>
            </div>
          </Col>

          <Col span={[3]} style={{ marginBottom: "10px" }}>
            <Select style={{ width: "100%" }} value={messageType} placeholder="Select" onChange={ChangeMessageType}>
              <Option text="Voice" value="Voice"></Option>
              <Option text="Text" value="Text"></Option>
              <Option text="Text" value="DDMF"></Option>
            </Select>
          </Col>
        </Row>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="inboundMessage" label="Inbound Message">
            <Select mode="multiple" placeholder="Select">
              {dropdownOptions.map((option) => (
                <Option key={option.groupId} value={option.groupName}>
                  {option.groupName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item style={{ margin: -9 }}>
            <Button type="link" onClick={showDrawer}>
              View Detail
            </Button>
          </Form.Item>

          <Form.Item label="Outbound Message" name="outboundMessage">
            <Row gutter={4} style={{ position: 'relative', border: '1px solid #d9d9d9', backgroundColor: '#fff', borderRadius: '7px' }}>
              <Col span={23}>
                <TextArea
                  rows={4}
                  placeholder="Type your outbound message here"

                  value={form.getFieldValue('outboundMessage')}
                  ref={textAreaRef}
                  onChange={handleTextAreaChange}
                  style={{ width: '100%', borderRadius: '7px' }}
                  className='border-0'
                />
              </Col>
              <Col span={1}>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Button type="primary" style={{ position: 'absolute', right: 5, top: 5 }}>
                    {"{ }"}
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            <br />
            <Form.Item name="analyzeCheckbox" valuePropName="checked" noStyle>
              <Checkbox onChange={handleCheckboxChange}>Analyze</Checkbox>
            </Form.Item>
            {isDropdownVisible && (
              <Form.Item name="analyzeType" noStyle>
                <Select style={{ marginTop: 10 }} placeholder="Select Analyze" onChange={handleAnalyzeTypeChange}>
                  <Option value="zipcode">Zipcode Analyze</Option>
                  <Option value="npi">NPI Analyze</Option>
                </Select>
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item>
            <Row justify="end" gutter={8}>
              <Col>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
              <Col>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>

      <Drawer
        title="Detail View"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <Form.Item label="Selected Inbound Message">
          <Select onChange={handleDrawerSelectChange}>
            {inboundMessageSelectedOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Table columns={columns} className="cus-table" dataSource={keywordResponse} rowKey="responseId" pagination={false} />
      </Drawer>
    </div>
  );
};

export default Inbound;
