import React, { useState, useRef, useEffect } from 'react';
import { Form, Select, Input, Button, Row, Col, Dropdown, Tooltip, Menu, Card } from 'antd';
import { SettingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchBranchInitialData } from '../../../services/componentService';
import { passOutputToParent, isJsonString } from '../../../assets/js/common.js';

const { Option } = Select;
const { TextArea } = Input;
const CONST_MAPPING = {
  NEXT_WORKFLOW: "NEXTWORKFLOW",
  SAME_COMPONENT: "SAMECOMPONENT",
  PROCESS_TYPE_API: 'API CONFIG',
  PROCESS_TYPE_SP: 'SP CALL',
  PROCESS_TYPE_MESSAGE: 'MESSAGE BUILD',
  OPERATOR_WORKFLOW_ID: 2,
  OPERATOR_LINE_TEXT: 'operator',
  MULTISELECT_MAX_DISPLAY: 2,
};

const Outbound = () => {
  const [form] = Form.useForm();
  const [selectedFieldType, setSelectedFieldType] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState('');
  const textAreaRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [responseOptions, setResponseOptions] = useState([]);
  const [nodeKey, setNodeKey] = useState("");
  const [messageType, setMessageType] = useState('Voice');

  useEffect(() => {
    const loadDropdownData = async () => {
      try {
        const response = await fetchBranchInitialData();
        if (response.data && response.data.messageIndexData) {
          setResponseOptions(response.data.messageIndexData);
          setDropdownOptions(response.data.messageIndexData);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    loadDropdownData();
  }, []);

  useEffect(() => {
    const fetchComponentData = () => {
      window.addEventListener('message', compDataLoad);
      return () => {
        window.removeEventListener('message', compDataLoad);
      };
    };
    fetchComponentData();
  }, []);

  const compDataLoad = (event) => {
    if (event.source === window.parent) {
      if (event.data) {
        setNodeKey(event.data.key);
        const incomingData = event.data;
        if (isJsonString(incomingData.data)) {
          const loadData = JSON.parse(incomingData.data);
          if (loadData.loadJson) {
            const processData = loadData.loadJson;
            if (processData.configData) {
              form.setFieldsValue(processData.configData);
            }
          }
        }
      }
    }
  };
  const ChangeMessageType = (value) => {
    setMessageType(value);
  }
  const onFinish = (values) => {
    const selectedResponses = values.possibleResponses || [];

    const lineItems = selectedResponses.map((response, index) => ({
      key: index + 1,
      lineType: 'nextcomponent',
      lineNextFlow: 0,
      lineNextFlowText: '',
      lineText: response,
    }));

    const defaultLines = {
      key: -1,
      lineType: "nextworkflow",
      lineNextFlow: CONST_MAPPING.OPERATOR_WORKFLOW_ID,
      lineText: CONST_MAPPING.OPERATOR_LINE_TEXT + "|" + CONST_MAPPING.OPERATOR_WORKFLOW_ID,
    };

    const linesData = { lines: [...lineItems, defaultLines] };
    const lineJson = {
      configData: {
        ...values,
        messageType,
      },
      configLines: linesData.lines,
    };
    const fullData = {
      key: nodeKey,
      configData: lineJson.configData,
      configLines: lineJson.configLines,
    };
    console.log("Full Data:", fullData);
    passOutputToParent(JSON.stringify(fullData));
  };


  const handleMenuClick = (e) => {
    const selectedType = `{${e.key}}`;
    setSelectedFieldType(selectedType);

    if (textAreaRef.current) {
      const textArea = textAreaRef.current.resizableTextArea.textArea;
      const cursorPosition = textArea.selectionStart;
      const text = textArea.value;

      const newText = text.slice(0, cursorPosition) + selectedType.replace(/@/g, '') + text.slice(cursorPosition);
      setTextAreaValue(newText);
      form.setFieldsValue({ outboundMessage: newText });
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    const filteredOptions = responseOptions.filter(option =>
      option.messageIndexData.toLowerCase().includes(value.toLowerCase())
    );
    setDropdownOptions(filteredOptions);
  };

  const menu = (
    <Menu onClick={handleMenuClick} style={{ height: '200px', overflow: 'auto' }}>
      <Menu.Item key="search" disabled>
        <Input
          placeholder="Search"
          value={searchInput}
          onChange={handleSearchChange}
          style={{ width: '100%' }}
        />
      </Menu.Item>
      {dropdownOptions.map(param => (
        <Menu.Item key={param.messageIndexData}>
          {param.messageIndexData.replace(/@/g, '')}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleCancel = () => {
    form.resetFields();
    setTextAreaValue('');
  };

  return (
    <div className='component-container'>
      <Card className='cus-card'
        title={
          <Row align="middle">
            <Col>
              <SettingOutlined className='cus-card-title-icon' />
            </Col>
            <Col>
              <span>Settings</span>
            </Col>
          </Row>
        }
      ><Row span={[24]}>
          <Col span={[3]}>
            <div className='cus_lable' style={{ marginBottom: "10px" }}>
              <label>Message Type</label>
              <span style={{ paddingLeft: "10px" }}>
                <Tooltip title="Change message type either 'Text' or 'Voice'. Default type is 'Voice'">
                  <ExclamationCircleOutlined />
                </Tooltip>
              </span>
            </div>
          </Col>

          <Col span={[3]} style={{ marginBottom: "10px" }}>
            <Select style={{ width: "100%" }} value={messageType} placeholder="Select" onChange={ChangeMessageType}>
              <Option text="Voice" value="Voice"></Option>
              <Option text="Text" value="Text"></Option>
              <Option text="Text" value="DDMF"></Option>
            </Select>
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="outboundMessage"
            label="Outbound Message"
          >
            <Row gutter={4} style={{ position: 'relative', border: '1px solid #d9d9d9', backgroundColor: '#fff', borderRadius: '7px' }}>
              <Col span={24}>
                <TextArea
                  rows={4}
                  placeholder="Type your outbound message here"
                  value={form.getFieldValue('outboundMessage')}
                  ref={textAreaRef}
                  onChange={(e) => {
                    const value = e.target.value;
                    setTextAreaValue(value);
                    form.setFieldsValue({ outboundMessage: value });
                  }}
                  style={{ width: 'calc(100% - 50px)', borderRadius: '7px', border: '0px !important' }}
                  className='border-0'
                />
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Button type="primary" style={{ position: 'absolute', right: 5, top: 5 }}>
                    {"{ }"}
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="possibleResponses"
            label="Possible Responses"
          >
            <Select
              mode="multiple"
              placeholder="Select responses"
            >
              {dropdownOptions.map(option => (
                <Option key={option.messageIndexData} value={option.messageIndexData}>
                  {option.messageIndexData.replace('@', '')}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Row justify="end" gutter={8}>
              <Col>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
              <Col>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Outbound;
