import React, { useState, useEffect } from 'react';
import { Checkbox, Input, Select, Button, Form, Row, Col,Tooltip, Card, Drawer, Table } from 'antd';
import { SettingOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchBranchInitialData } from '../../../services/componentService';
import { fetchResponseDataByGroupId } from '../../../services/admin/adminService';
import { passOutputToParent, isJsonString } from '../../../assets/js/common.js';

const { TextArea } = Input;
const { Option } = Select;

const MAPPING_CONST = {
  GETKEYWORD: 'GETKEYWORD',
};
const CONST_MAPPING = {
  NEXT_WORKFLOW: "NEXTWORKFLOW",
  SAME_COMPONENT: "SAMECOMPONENT",
  PROCESS_TYPE_API: 'API CONFIG',
  PROCESS_TYPE_SP: 'SP CALL',
  PROCESS_TYPE_MESSAGE: 'MESSAGE BUILD',
  OPERATOR_WORKFLOW_ID: 2,
  OPERATOR_LINE_TEXT: 'operator',
  MULTISELECT_MAX_DISPLAY: 2,
}

const InboundAnalyze = () => {
  const [form] = Form.useForm();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [keywordResponse, setKeywordResponse] = useState([]);
  const [nodeKey, setNodeKey] = useState("");
  const [messageType, setMessageType] = useState('Voice');


  useEffect(() => {
    const loadDropdownData = async () => {
      try {
        const response = await fetchBranchInitialData();
        if (response.data && response.data.possibleResponseGroup) {
          setDropdownOptions(response.data.possibleResponseGroup);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    loadDropdownData();
  }, []);

  useEffect(() => {
    async function fetchComponentData() {
      try {
        window.addEventListener('message', compDataLoad);
        return () => {
          window.removeEventListener('message', compDataLoad);
        };
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    }
    fetchComponentData();
  }, []);
  const ChangeMessageType = (value) => {
    setMessageType(value);
}
  const compDataLoad = (event) => {
    if (event.source === window.parent) {
      if (event.data) {
        setNodeKey(event.data.key);
        const incomingData = event.data;
        if (isJsonString(incomingData.data)) {
          const loadData = JSON.parse(incomingData.data);
          if (loadData.loadJson) {
            const processData = loadData.loadJson;
            if (processData.configData) {
              form.setFieldsValue(processData.configData);
            }
          }
        }
      }
    }
  };

  const showDrawer = () => {
    const formValues = form.getFieldsValue();
    setSelectedGroup(formValues.possibleResponses || []);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setKeywordResponse([]);
  };

  const handleDrawerSelectChange = async (value) => {
    try {
      const selectedOption = dropdownOptions.find(option => option.groupName === value);
      if (selectedOption) {
        const result = await fetchResponseDataByGroupId(selectedOption.groupId, MAPPING_CONST.GETKEYWORD);
        setKeywordResponse(result);
      }
    } catch (error) {
      console.error('Failed to fetch keyword response:', error);
    }
  };

  const columns = [
    {
      title: 'Keyword Response',
      dataIndex: 'response',
      key: 'response',
    },
  ];

  const onFinish = (values) => {
    const selectedResponses = values.possibleResponses || [];

    const lineItems = selectedResponses.map((response, index) => ({
      key: index + 1,
      lineType: 'nextcomponent',
      lineNextFlow: 0,
      lineNextFlowText: '',
      lineText: response,
    }));

    const defaultLines = { key: -1, lineType: "nextworkflow", lineNextFlow: CONST_MAPPING.OPERATOR_WORKFLOW_ID, lineText: CONST_MAPPING.OPERATOR_LINE_TEXT + "|" + CONST_MAPPING.OPERATOR_WORKFLOW_ID };
    let linesData = { lines: lineItems };
    linesData.lines = [...linesData.lines, defaultLines];

    const compData = {
      key: nodeKey,
      configData: values,
      configLines: linesData,
    };

    passOutputToParent(JSON.stringify(compData));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="component-container"
      onFinish={onFinish}
    >
      <Card
        className="cus-card"
        title={
          <Row align="middle">
            <Col>
              <SettingOutlined className="cus-card-title-icon" />
            </Col>
            <Col>
              <span>Settings</span>
            </Col>
          </Row>
        }
      ><Row span={[24]}>
      <Col span={[3]}>
          <div className='cus_lable' style={{ marginBottom: "10px" }}>
              <label>Message Type</label>
              <span style={{ paddingLeft: "10px" }}>
                  <Tooltip title="Change message type either 'Text' or 'Voice'. Default type is 'Voice'">
                      <ExclamationCircleOutlined />
                  </Tooltip>
              </span>
          </div>
      </Col>

      <Col span={[3]} style={{ marginBottom: "10px" }}>
          <Select style={{ width: "100%" }} value={messageType} placeholder="Select" onChange={ChangeMessageType}>
              <Option text="Voice" value="Voice"></Option>
              <Option text="Text" value="Text"></Option>
              <Option text="Text" value="DDMF"></Option>
          </Select>
      </Col>
  </Row>

        <Form.Item name="sendMessage" valuePropName="checked">
          <Checkbox>
            Send Message
          </Checkbox>
        </Form.Item>
        <Form.Item name="outboundMessage">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="possibleResponses" label="Possible Response Group">
          <Select
            mode="multiple"
            placeholder="Select possible responses"
            style={{ width: '100%' }}
          >
            {dropdownOptions.map((option) => (
              <Option key={option.groupId} value={option.groupName}>
                {option.groupName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={showDrawer}>
            View Detail
          </Button>
          <Form.Item name="openAIAnalyze" valuePropName="checked">
            <Checkbox>
              Open AI Analyze
            </Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Row justify="end" gutter={8}>
            <Col>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
            <Col>
              <Button type="default" onClick={() => form.resetFields()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
      <Drawer
        title="Detail View"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <Form.Item label="Selected Inbound Message">
          <Select onChange={handleDrawerSelectChange}>
            {selectedGroup.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Table columns={columns} className="cus-table" dataSource={keywordResponse} rowKey="responseId" pagination={false} />
      </Drawer>
    </Form>
  );
};

export default InboundAnalyze;
