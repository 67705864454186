
import axiosInterceptor from "../middleware/axiosInterceptor";

export const fetchBranchInitialData = async () => {
    const url = "/api/workflow/Component/branch/initial";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};
export const fetchComponentInitialData = async () => {
    const url = "/api/workflow/Component/create/initial";

    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};

export const fetchComponentCategoryCreateUpdateData = async (categoryId, categoryName) => {
    const url = "/api/workflow/Component/category/create";
    const bodyData = { categoryId, categoryName };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};
export const fetchWorkFlowData = async () => {
    const url = "/api/workflow/Components";

    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};
export const saveWorkflowComponent = async (data) => {
    const url = "/api/workflow/Component/create";  
    try {
        const response = await axiosInterceptor.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};
export const UpdateWorkflowComponent = async (componentId) => {
    const url = `/api/workflow/Component/${componentId}`;
    try {
    
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error; 
    }
};
export const DeleteWorkflowComponent = async (componentId) => {
    const url = `/api/workflow/Component/Delete/${componentId}`;
    try {
    
        const response = await axiosInterceptor.delete(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error; 
    }
};
export const UpdateWorkflowCategory = async (categoryId) => {
    const url = `/api/workflow/Category/${categoryId}`;
    try {
    
        const response = await axiosInterceptor.get(url);
        console.error(response.data);
        return response.data;
        
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error; 
    }
};
export const DeleteWorkflowCategory = async (categoryId) => {
    const url = `/api/workflow/Category/Delete/${categoryId}`;
    try {
    
        const response = await axiosInterceptor.delete(url);
        console.error(response.data);
        return response.data;
        
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error; 
    }
};
