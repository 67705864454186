import React from 'react';
const { REACT_APP_BASE_API_URI } = process.env;

const workflow = () => {
  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <iframe
        src={`${REACT_APP_BASE_API_URI}workflows`}
        title="workflows"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',  // Remove border if you want a seamless look
        }}
        allowFullScreen
      />
    </div>
  );
};

export default workflow;
