
import axiosInterceptor from "../../middleware/axiosInterceptor";

export const fetchResponseGroupInitialData = async () => {
    const url = "/api/admin/response/groups";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const fetchResponseDataByGroupId = async (groupId, getTypeAction) => {
    const url = "/api/admin/response/group/get/" + `${getTypeAction}` + `/${groupId}`;
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.groups;

    }
    catch (error) {
        console.error(error);
    }
};

export const addNewKeyword = async (keywordText) => {
    const url = "/api/admin/response/group/add";
    const bodyData = { name: keywordText };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const updateKeyword = async (id, keywordText) => {
    const url = "/api/admin/response/group/update";
    const bodyData = { keywordId: id, name: keywordText };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const deleteGroupByGroupId = async (responseGroupId) => {
    const url = "/api/admin/response/group/delete";
    const bodyData = { groupId: responseGroupId };
    try {
        const response = await axiosInterceptor.delete(url, { data: bodyData });
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};


export const responseAddToGroup = async (responseGroupId, responseGroupName, responseValue, type) => {
    const url = "/api/admin/response/group-response/add";
    const bodyData = { groupId: responseGroupId, groupName: responseGroupName, responseText: responseValue, responseType: type };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const responseDeleteToGroup = async (responseRowId, type) => {
    const url = "/api/admin/response/group-response/delete";
    const bodyData = { responseId: responseRowId, responseType: type };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const responseUpdateToGroup = async (responseGroupId, resId, responseValue, type) => {
    const url = "/api/admin/response/group-response/update";
    const bodyData = { groupId: responseGroupId, responseId: resId, responseText: responseValue, responseType: type };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};
export const authenticate = async (userName, password) => {
    const url = '/api/authenticate';
    const bodyData = { userName: userName, password: password };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const metaDataLoad = async () => {
    const url = "/api/admin/metadata";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.data;

    }
    catch (error) {
        console.error(error);
    }
};

export const metaDataAdd = async (id, name, dataType, communicationType, typeCase) => {
    const url = "/api/admin/metadata/value/add";
    const bodyData = { parameterId: id, parameterName: name, parameterDataType: dataType, communicationType: communicationType, typeCase: typeCase };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data.isExists;

    }
    catch (error) {
        console.error(error);
    }
};
export const metaDataUpdate = async (parameterId) => {
   
    const url = "/api/admin/metadata/value/get/" + `${parameterId}`;

    try {
        const response = await axiosInterceptor.get(url);
        console.log(response);
        return response.data;

    }
    catch (error) {
        console.error(error);
    }
};
export const metaDataDelete = async (parameterId) => {
    const url = "/api/admin/metadata/value/delete/" + `${parameterId}`;
    try {
        const response = await axiosInterceptor.delete(url);
        console.log(response);
        return response.data;

    }
    catch (error) {
        console.error(error);
    }
};