import React, { useState } from 'react';
import { Input, Select, Button, Drawer, Table, Space } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const DemoComp = () => {
    const defaultData = [
        { key: '1', messageName: 'Welcome', messageOrder: '1', messageAPI: 'Phone Number', message: 'Hello, I am @PracticeName Virtual Assistant, at the @BotLocationName office, and will assist you with your appointments. This call is being recorded for quality purposes *{}* If you are a new patient, say Yes, else, say No.' },
        { key: '2', messageName: 'Ask DOB', messageOrder: '2', messageAPI: 'DOB', message: 'Ok. To proceed, I need some information. what is your date of birth?' },
        { key: '3', messageName: 'Ask Zip', messageOrder: '3', messageAPI: 'Zip Code', message: 'And what is your zip code?' }
    ];
    const [messages, setMessages] = useState(defaultData);
    const [fallbackMessages, setFallbackMessages] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [currentMessage, setCurrentMessage] = useState({
        messageName: '',
        messageOrder: 1,
        message: '',
        messageAPI: '',
        fallback: false,
    });
    const [currentFallbackMessage, setCurrentFallbackMessage] = useState({
        fallbackOrder: 1,
        fallbackMessage: '',
    });
    const [userView, setuserView] = useState(false);

    const handleAddMessage = () => {
        setMessages([...messages, currentMessage]);
        setCurrentMessage({
            messageName: '',
            messageOrder: 1,
            message: '',
            messageAPI: '',
            fallback: false,
        });
    };

    const handleUserView = () => {
        setuserView((prevState) => !prevState);
    }

    const handleAddFallbackMessage = () => {
        setFallbackMessages([...fallbackMessages, currentFallbackMessage]);
        setCurrentFallbackMessage({
            fallbackOrder: 1,
            fallbackMessage: '',
        });
    };

    const handleDeleteMessage = (index) => {
        setMessages(messages.filter((_, i) => i !== index));
    };

    const handleDeleteFallbackMessage = (index) => {
        setFallbackMessages(fallbackMessages.filter((_, i) => i !== index));
    };



    const columns = [
        { title: 'Message Name', dataIndex: 'messageName', key: 'messageName' },
        { title: 'Message Order', dataIndex: 'messageOrder', key: 'messageOrder' },
        { title: 'Search By', dataIndex: 'messageAPI', key: 'messageAPI' },
        {
            title: 'FallBack Message', key: 'fallBackmessage',
            render: (_, __, index) => (
                <Space>
                    <Button icon={<EditOutlined />} onClick={() => setDrawerVisible(true)} />
                </Space>
            ),
        },
        { title: 'Message', dataIndex: 'message', key: 'message' },
        {
            title: 'Action',
            key: 'action',
            render: (_, __, index) => (
                <Space>
                    <Button icon={<EditOutlined />} />
                    <Button icon={<DeleteOutlined />} onClick={() => handleDeleteMessage(index)} />
                </Space>
            ),
        },
    ];

    const fallbackColumns = [
        { title: 'Fallback Order', dataIndex: 'fallbackOrder', key: 'fallbackOrder' },
        { title: 'Fallback Message', dataIndex: 'fallbackMessage', key: 'fallbackMessage' },
        {
            title: 'Action',
            key: 'action',
            render: (_, __, index) => (
                <Space>
                    <Button icon={<EditOutlined />} />
                    <Button icon={<DeleteOutlined />} onClick={() => handleDeleteFallbackMessage(index)} />
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: '10px' }}>
            {/* <Button type="primary" onClick={handleUserView}>
                User View
            </Button> */}
            {/* Message Form */}
            {userView == false &&
                <Space direction="vertical" style={{ width: '100%' }}>
                    <label>Message Name</label>
                    <Input
                        placeholder="Message Name"
                        value={currentMessage.messageName}
                        onChange={(e) =>
                            setCurrentMessage({ ...currentMessage, messageName: e.target.value })
                        }
                    />
                    <label>Message Order</label>
                    <Select
                        style={{ width: '100%' }}
                        value={currentMessage.messageOrder}
                        onChange={(value) =>
                            setCurrentMessage({ ...currentMessage, messageOrder: value })
                        }
                    >
                        {[1, 2, 3, 4, 5].map((order) => (
                            <Option key={order} value={order}>
                                {order}
                            </Option>
                        ))}
                    </Select>
                    <label>Seach by</label>
                    <Select
                        style={{ width: '100%' }}
                        value={currentMessage.messageAPI}
                        onChange={(value) =>
                            setCurrentMessage({ ...currentMessage, messageAPI: value })
                        }
                    >
                        {['Phone Number', 'DOB', 'Zip Code'].map((order) => (
                            <Option key={order} value={order}>
                                {order}
                            </Option>
                        ))}
                    </Select>
                    <label>Type your Message</label>
                    <TextArea
                        rows={4}
                        placeholder="Message"
                        value={currentMessage.message}
                        onChange={(e) =>
                            setCurrentMessage({ ...currentMessage, message: e.target.value })
                        }
                    />
                    <Button icon={<PlusOutlined />} onClick={() => setDrawerVisible(true)}>
                        Add Fallback
                    </Button>
                    <Button type="primary" onClick={handleAddMessage}>
                        Add Message
                    </Button>
                </Space>
            }

            {/* Message Grid */}
            <h4>Message List</h4>
            <Table columns={columns} dataSource={messages} rowKey={(record, index) => index} />

            {/* Fallback Drawer */}
            <Drawer
                title="Fallback Message"
                placement="right"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
            >
                <label>Fallback Message Order</label>
                <Select
                    value={currentFallbackMessage.fallbackOrder}
                    onChange={(value) =>
                        setCurrentFallbackMessage({ ...currentFallbackMessage, fallbackOrder: value })
                    }
                    style={{ marginBottom: '16px', width: '100%' }}
                >
                    {[1, 2, 3, 4, 5].map((order) => (
                        <Option key={order} value={order}>
                            {order}
                        </Option>
                    ))}
                </Select>
                <label>Fallback Message</label>
                <TextArea
                    rows={4}
                    placeholder="Fallback Message"
                    value={currentFallbackMessage.fallbackMessage}
                    onChange={(e) =>
                        setCurrentFallbackMessage({
                            ...currentFallbackMessage,
                            fallbackMessage: e.target.value,
                        })
                    }
                    style={{ marginBottom: '16px' }}
                />
                <Button type="primary" onClick={handleAddFallbackMessage} style={{ marginBottom: '16px' }}>
                    Add Fallback Message
                </Button>

                {/* Fallback Message Grid */}
                <Table
                    columns={fallbackColumns}
                    dataSource={fallbackMessages}
                    rowKey={(record, index) => index}
                />
            </Drawer>
            <Button type="primary" style={{ marginBottom: '16px', float: 'right' }}>
                Save
            </Button>
        </div>
    );
};

export default DemoComp;
